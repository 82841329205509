.ag-panel-container {
    padding-left: 10px;
}

.btn-icon {
    font-size: 20px;
    vertical-align: middle;
    color: #707070;
    cursor: pointer;
}

.btn-icon:hover {
    color: #343434;
}

.btn-icon-disabled {
    color: #a7a7a7;
    cursor: default;
}

.btn-icon-disabled:hover {
    color: #a7a7a7;
}

input[type=checkbox] {
    vertical-align: middle;
}
